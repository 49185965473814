.css-w4xala{
    padding-top: 120px !important;
}
.css-43vp96-MuiPaper-root-MuiCard-root{
    overflow: auto;
}
.css-17pmaar-MuiCardContent-root{
    min-width: 280px;
}
.css-jv08h3-MuiPaper-root-MuiAppBar-root{
    height: 80px !important;
}
.css-aszp7s-MuiTable-root{
 min-width: 720px;       
}
.green-border{
    border: 1px solid green;
}
.red-border{
    border: 1px solid #ef4545;
}
.no-border{
    border: none
}
.folder-view {
    width: 82px !important;
    flex-basis: 82px !important;
    transition: 0.4s ease-in-out;
    position: relative;
}
.folder-veiw-table{
    max-height: 500px;
    overflow-y: auto !important;
}
.folder-veiw-table::-webkit-scrollbar{
    width: 5px;
}
.text-container {
    height: 800px; 
    overflow-y: auto; 
    padding: 16px; 
    border: 1px solid #ccc;
    border-radius: 8px; 
    background-color: #f4f4f4;
    font-family: 'Monospace', monospace; 
    white-space: pre-wrap; 
    word-wrap: break-word; 
  }
  /* Customize the scrollbar */
.text-container::-webkit-scrollbar {
    width: 12px; /* Width of the scrollbar */
  }
  
  .text-container::-webkit-scrollbar-thumb {
    background-color: #888; /* Color of the thumb (the part you drag) */
    border-radius: 10px; /* Rounded corners for the thumb */
    border: 3px solid transparent; /* Adds spacing around the thumb */
    background-clip: content-box; /* Ensures the border is not overlaid on the thumb */
  }
  
  .text-container::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Color of the thumb when hovered */
  }
  
  .text-container::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Color of the track (background of the scrollbar) */
    border-radius: 10px; /* Rounded corners for the track */
  }
  
  .text-container::-webkit-scrollbar-track:hover {
    background-color: #e0e0e0; /* Track color when hovered */
  }
  
  .text-container::-webkit-scrollbar-button {
    display: none; /* Hide the buttons (arrows) on the scrollbar */
  }
.folder-veiw-table::-webkit-scrollbar-thumb{
    border-radius: 2.5px;
    background: #eeeeee;
}
/* Styling for the JSON container */
.json-container {
    height: 800px; /* Fixed height */
    overflow-y: auto; /* Enable vertical scrolling */
    padding: 16px; /* Padding inside the container */
    border: 1px solid #ccc; /* Optional: border around the text box */
    border-radius: 8px; /* Optional: rounded corners */
    background-color: #f9f9f9; /* Lighter background color for JSON */
    font-family: 'Courier New', Courier, monospace; /* Monospace font for JSON */
    white-space: pre-wrap; /* Preserve text formatting */
    word-wrap: break-word; /* Break long words */
    color: #333; /* Set text color */
    max-height: 700px;   /* Fixed height for the container */
  }
  
  /* Customizing the scrollbar for JSON container */
  .json-container::-webkit-scrollbar {
    width: 12px;
  }
  
  .json-container::-webkit-scrollbar-thumb {
    background-color: #7e7e7e;
    border-radius: 10px;
  }
  
  .json-container::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 10px;
  }
  
  /* Optional: Syntax Highlighting for JSON */
  .json-container span {
    color: #d9534f; /* Red for keys */
  }
  
  .json-container .json-key {
    color: #d9534f;
  }
  
  .json-container .json-string {
    color: #5bc0de;
  }
  
  .json-container .json-number {
    color: #5bc0de;
  }
  
  .json-container .json-boolean {
    color: #f0ad4e;
  }
.table-check-wrapper{
    position: absolute;
    bottom: 10px;
    left: 30px;
}
.folder-view.open {
    width: 33.33% !important;
    flex-basis: 33.33% !important;
}
.folder-view .MuiPaper-elevation{
    width: 0 !important;
    overflow: hidden;
    transition: 0.4s ease-in-out;
}
.folder-view.open .MuiPaper-elevation{
    width: 100% !important;
}
.folder-view .toggle-btn{
    position: absolute;
    background-color: #eeeeee;
    top: 36px;
    padding: 8px !important;
    right: 0px;
    z-index: 100;
}
.status-error{
    max-height: 52px;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    text-align: center;
    padding: 6px 16px;
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 14px;
    color: #ef4545;
    background-color: #ffe9e9;
    border-radius: 12px;
    text-align: left;
}
.status-error button{
    background-color: unset;
    border: unset;
    color: #0271da;
    text-decoration: underline;
    display: inline-block;
    font-size: 14px;
    margin-left: 5px;
    cursor: pointer;
}
.status-error.expand{
    max-height: 188px;
    overflow-y: auto;
}
.status-error.expand::-webkit-scrollbar{
    width: 4px;
}
.status-error.expand::-webkit-scrollbar-thumb{
    border-radius: 2px;
    background-color: #eeeeee;
}

@media only screen and (max-width: 1440px) {
    .css-9drkqd-MuiGrid-root{
        padding-left: 0px !important;
    }
    .css-jpnrz7-MuiFormControlLabel-root{
        display: block;
        width: 100%;
    }
    .css-b1im03-MuiGrid-root{
        flex-basis: 50% !important;
    }
    .css-1lhy94w-MuiGrid-root, .css-12xeub0-MuiGrid-root, .css-1anr466-MuiGrid-root{
        flex-basis: 50% !important;
        max-width: 50% !important;
    }
    .css-5qhx1r-MuiFormLabel-root{
        margin-right: 20px;
    }
    .css-xn6ruc{
        width: 38% !important;
    }
    .css-xn6ruc .MuiTextField-root{
        width: 15ch;
    }
    .css-1ube6tp-MuiGrid-root{
        flex-basis: 45% !important;
        max-width: 45% !important;
    }
}

.react-pdf__Document {
  height: calc(100% - 50px);
}

#pdf-page-wrapper {
  height: calc(100% - 20px);
}

.react-pdf__Page {
  height: 100%;
}

.react-pdf__Page__canvas {
  max-height: 100%;
}

.react-pdf__Page__textContent {
  max-height: 100%;
}
.email-container {
  width: 100%;
  max-width: 900px;
  height: 700px; /* Fixed height for the container */
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow-y: auto;
  padding: 1rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  line-height: 1.6;
  background-color: #f9f9f9;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  color: #333;
  position: relative;
}

/* Header Styles */
.email-header {
  border-bottom: 1px solid #ddd;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
}

.email-subject {
  font-size: 1.5rem;
  margin: 0;
  color: #4a90e2;
  font-weight: 500;
}

.email-from,
.email-to {
  font-size: 0.9rem;
  color: #555;
  margin: 0.2rem 0;
}

/* Body Styles */
.email-body-container {
  margin-top: 1rem;
}

.email-body {
  background: #fff;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  white-space: pre-wrap;
}

/* Footer Styles */
.email-footer {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #ddd;
}

.attachments-title {
  font-size: 1rem;
  margin: 0 0 0.5rem;
  color: #4a90e2;
}

.attachments-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.attachment-item {
  margin-bottom: 0.5rem;
}

.attachment-link {
  text-decoration: none;
  color: #007bff;
  font-size: 0.9rem;
}
.scrollable-container {
  scrollbar-width: thin; /* Firefox: Makes the scrollbar thinner */
  scrollbar-color: #bbb transparent; /* Firefox: Sets scrollbar and track colors */
}

.scrollable-container::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.scrollable-container::-webkit-scrollbar-thumb {
  background-color: #bbb; /* Color of the scrollbar thumb */
  border-radius: 4px; /* Round edges */
}

.scrollable-container::-webkit-scrollbar-thumb:hover {
  background-color: #999; /* Darker thumb on hover */
}

.scrollable-container::-webkit-scrollbar-track {
  background: transparent; /* Transparent track */
}

.scrollable-container:hover::-webkit-scrollbar {
  opacity: 1; /* Show scrollbar on hover */
}

.scrollable-container::-webkit-scrollbar {
  opacity: 0; /* Hide scrollbar by default */
  transition: opacity 0.3s;
}